<template>
  <div>
		<img src="../../assets/img/whistleForm-banner.png" alt="" />
    <van-form @submit="onSubmit" class="form-container">
      <van-field v-model="formData.name" name="name" label="名字" placeholder="请输入名字" class="input-field" style="margin-top: 0;" />
      <van-radio-group v-model="formData.gender" class="radio-group">
        <van-radio name="男" :value="'男'" class="radio-item">男</van-radio>
        <van-radio name="女" :value="'女'" class="radio-item">女</van-radio>
      </van-radio-group>
      <van-field v-model="formData.phone" name="phone" label="电话" maxlength="11" placeholder="请输入电话" class="input-field" />
      <van-field v-model="formData.peopleNumber" name="peopleNumber" label="人数" placeholder="请输入人数" type="number" class="input-field" />
      <div class="starDiv">
      	<p class="starTit">星级</p>
      	<van-rate v-model="formData.star" :count="10" class="rate-component" />
      </div>
      <div style="margin: 16px;">
        <van-button type="primary" round block native-type="submit" class="submit-button">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import Vue from 'vue';
import { Form, Field, RadioGroup, Radio, Rate, Button } from 'vant';
import { saveBaoMing } from "../../assets/api/login.js";

Vue.use(Form);
Vue.use(Field);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Rate);
Vue.use(Button);

export default Vue.extend({
  data() {
    return {
      formData: {
        name: '',
        gender: '',
        phone: '',
        peopleNumber: '',
        star: 0
      }
    };
  },
  methods: {
		// 报名
		saveBaoMing() {
			saveBaoMing({
				name: this.formData.name,
				phone: this.formData.phone,
				sexy: this.formData.gender,
				num: this.formData.peopleNumber,
				star: this.formData.star
			}).then((res) => {
			  console.log(res, '报名');
			  if (res.code == 0) {
					this.Toast('报名成功~');
			  } else{
			    this.Toast(res.msg)
			  }
			})
		},
		
    onSubmit() {
      // 可以在这里添加提交表单数据后的处理逻辑，比如发送到服务器
      console.log(this.formData);
			if (this.formData.name == '') {
				this.Toast('请填写名字');
			} else if (this.formData.phone == '') {
				this.Toast('请填写手机号');
			} else if (this.formData.gender == '') {
				this.Toast('请选择性别');
			} else if (this.formData.peopleNumber == '') {
				this.Toast('请填写人数');
			} else if (this.formData.star == 0) {
				this.Toast('请选择星级');
			} else {
				// 报名
				this.saveBaoMing();
			}
    }
  }
});
</script>

<style scoped>
	::v-deep input {
		font-size: 0.28rem !important;
	}
	
	::v-deep .van-radio__label {
		font-size: 0.28rem;
	}
	
	::v-deep .van-field__label {
		width: 2.3em;
	}
	
	.starDiv {
		display: flex;
		align-items: center;
		border: 1px solid #e5e5e5;
		border-radius: 5px;
		margin-top: 15px;
		box-sizing: border-box;
		width: 100%;
		padding: 10px 16px;
		overflow: hidden;
		color: #323233;
		font-size: 14px;
		line-height: 24px;
		background-color: #fff;
	}
	.starTit {
		width: 3.3em !important;
		font-size: 0.26rem;
		color: #646566;
	}
	
.form-container {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.input-field {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-top: 15px;
}

.input-field:focus {
  border-color: #07c160;
}

.radio-group {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.radio-item {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px;
  width: 40%;
  text-align: center;
	justify-content: center;
}

.radio-item.van-radio--checked {
  background-color: #07c160;
}

.rate-component {
  margin-top: 0px;
}

.rate-component.van-rate__icon {
  color: #ffd21e;
}

.submit-button {
  background-color: #07c160;
  border-color: #07c160;
}

.submit-button:active {
  background-color: #06ae56;
  border-color: #06ae56;
}

</style>